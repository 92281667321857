import React, { useState } from "react";
import { useLocation } from "wouter";
import {
  getCurrentUser,
  login as pollworkerLogin
} from "../fetchers";
import { setCurrentPollworkerUser } from "../state/actions";
import { Login } from "shared/src/components";
import { useAppDispatch } from "../hooks";
import { getWebsiteUserFromLocalStorage } from "../utils/localStorage";
import * as Sentry from 'shared/src/setupSentry';

export default function PollworkerLogin() {
  const [loginError, setLoginError] = useState();
  const [, navigate] = useLocation();
  const dispatch = useAppDispatch();

  const websiteUser = getWebsiteUserFromLocalStorage();

  const logoUrl = websiteUser ? `${process.env.REACT_APP_DOMAIN}/images/${websiteUser.CustomerId}/logo` : undefined;

  return (
    <div className='my-32'>
      <Login onForgotPassword={() => navigate('~/login/forgot')} errorMessage={loginError} logo={logoUrl} login={(username, password) => {
        return pollworkerLogin(username, password).then(async ([success, data]) => {
          console.log([success, data]);
          if (!success || !data.AuthenticatedUser) {
            setLoginError(data.LoginMessage);
            return [success, data];
          }

          window.localStorage.setItem('ZumoToken', data.AuthenticatedUser.ZumoToken);
          window.localStorage.setItem('CurrentUser', data.AuthenticatedUser.UserId);

          const resp = await getCurrentUser(data.AuthenticatedUser.UserId);

          Sentry.identifyLoggedInUser(resp.EVUserId, resp.UserId, '', resp.Customer.Id, resp.Customer.Name, resp.Customer.State);dispatch(setCurrentPollworkerUser(resp));

          dispatch(setCurrentPollworkerUser(resp));
          navigate('~/news');

          return [success, data];
        });
      }} />
    </div>
  );
}
