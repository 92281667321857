import React, { useEffect, useState } from 'react';
import {
  dropClass,
  getScheduledClasses,
} from "../fetchers";
import {useSelector} from "react-redux";
import Modal from "shared/src/components/Modal";
import PageEmptyState from "./PageEmptyState";
import { AcademicCapIcon, ArrowLeftIcon, } from "@heroicons/react/24/outline";
import { Link } from "wouter";
import { Flexor, Spinner } from "shared/src/components";
import { renderDataAttribute } from "./WorkSchedules";
import { ManageScheduledClass } from "./ModalManageScheduledClass";
import { toast } from "react-toastify";
import { Button } from "shared/src/components/ui/Button";

export type Document = {
  Id: string,
  Name: string,
  Url: string,
  UploadDate: string,
  UploadBy: string
}

export type ClassSession = {
  ClassName: string,
  Location: string,
  Instructor: string,
  ContactPhone: string,
  StartDate: string,
  StartTime: string,
  EndTime: string,
  Address: string,
  Room: string,
  Capacity: number,
  SeatsFilled: number,
  Documents: Document[],
}


export default function ScheduledClasses() {
  const selectedElectionId = useSelector((state: any) => state.election.electionId);
  const currentUser = useSelector((state: any) => state.currentPollworkerUser);
  const [scheduledClasses, setScheduledClasses] = useState<any[]>([]);
  const [scheduledClassId, setScheduledClassId] = useState<string>('');
  const [showScheduleView, setShowScheduleView] = useState(false);
  const [showDropClassView, setShowDropClassView] = useState(false);
  const [loadingScheduledClasses, setLoadingScheduledClasses] = useState(false);

  useEffect(() => {
    loadScheduledClasses();
  }, [selectedElectionId]);

  function loadScheduledClasses() {
    if (!selectedElectionId) return;

    setLoadingScheduledClasses(true);

    getScheduledClasses(selectedElectionId).then((response) => {
      setScheduledClasses(response);
    }).finally(() => {
      setLoadingScheduledClasses(false);
    });
  }

  function dropClassSessionData(sessionId: string) {
    return {
      EVUserId: currentUser.EVUserId,
      SessionId: sessionId,
    };
  }

  async function dropClassSession(sessionId: string) {
    dropClass(dropClassSessionData(sessionId), currentUser).then((success) => {
      // early return
      if (!success) {
        toast('An error occured.', {
          type: 'error', closeButton: (<div></div>), autoClose: 2000,
        });
        setShowDropClassView(false);
    }

      toast('You have successfully dropped this class session.', {
        type: 'success', closeButton: (<div></div>), autoClose: 2000,
      });
      setShowDropClassView(false);
      loadScheduledClasses();

      getScheduledClasses(selectedElectionId).then((response) => {
        setScheduledClasses(response);
      });
    });
  }

  return (
    <div className={'flow-root mt-2'}>
      <div>
        <Modal title='Drop Class Session' open={showDropClassView}
               onClose={() => setShowDropClassView(false)}
        >
          <div className="sm:flex sm:items-center">
            <p className="my-2 text-md text-gray-700">
              Are you sure you want to remove yourself from this training session?
            </p>
          </div>
          <div className="flex content-center justify-center py-2 mx-auto">
            <Button className="mr-4" onClick={() => dropClassSession(scheduledClassId)}>
              Yes
            </Button>
            <Button onClick={() => {setShowDropClassView(false); setShowScheduleView(true);}}>
              No
            </Button>
          </div>
        </Modal>
      </div>

      <ManageScheduledClass setShowDropClassView={setShowDropClassView} setShowScheduleView={setShowScheduleView} showScheduleView={showScheduleView} scheduledClassId={scheduledClassId} />

      <div>
        <h1 className="text-base font-semibold leading-6 text-gray-900 mb-4">Scheduled Classes</h1>
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700">
            This list contains the class sessions which you are attending. You may cancel your reservation
            and reschedule to attend a different session. Be sure to view the attached documents so that
            you can meet the requirements for the subject matter in which you are to be trained.
          </p>
        </div>
      </div>

      {
        loadingScheduledClasses ? (
          <div className='my-24 flex flex-col justify-center items-center'>
            <Flexor className='space-x-3'>
              <Spinner />
              <span>Loading scheduled classes...</span>
            </Flexor>
          </div>
        ) : (
          <>
            {
              scheduledClasses.length ? (
                <ul className="divide-y divide-gray-300">
                  {
                    scheduledClasses?.map((scheduledClass) => (
                      <li key={scheduledClass.Id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                          <div className="flex items-start gap-x-3">
                            <p
                              className="text-sm font-semibold leading-6 text-gray-900">{scheduledClass.ClassName || 'Not specified'}</p>
                          </div>
                          <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <span role="note">
                              <time id='classdate'
                                dateTime={scheduledClass.ClassDate}>{scheduledClass.ClassDate || 'Not specified'}
                              </time> <span id='at'>at</span> <time id='time'
                                dateTime={scheduledClass.startTime}> {scheduledClass.StartTime}
                              </time> at <span id='location'>{scheduledClass.Venue}, {renderDataAttribute(scheduledClass.Address)}</span>
                            </span>
                        </div>
                      </div>
                    <div className="flex flex-none items-center gap-x-4">
                          <Button
                            type="button"
                            id={`manage-class-${scheduledClass.Id}`}
                            onClick={() => {setShowScheduleView(true);
                              setScheduledClassId(scheduledClass.Id);
                            }}
                          >
                            Manage Class
                          </Button>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              ) : (
                <div className='my-10'>
                  <PageEmptyState icon={AcademicCapIcon} emptyMessageHeader='No scheduled classes' emptyMessageSubHeader='No classes have been scheduled. Sign up for classes on the Required Classes page.'>
                    <Link to='~/classes/required'>
                      <Button className='flex mx-auto mt-4'>
                        <ArrowLeftIcon className='h-4 w-4' />
                        <span>Go to Required Classes</span>
                      </Button>
                    </Link>
                  </PageEmptyState>
                </div>
              )
            }
          </>
        )
      }
    </div>
  )
}


