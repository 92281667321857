import React, { useEffect, useState } from "react";
import {getWorkSchedule} from "../fetchers";
import {
  CalendarDaysIcon,
  CalendarIcon, ChevronDownIcon,
  TableCellsIcon
} from "@heroicons/react/24/outline";
import ModalWorkScheduleConfirmation from "./ModalWorkScheduleConfirmation";
import Modal from "shared/src/components/Modal";
import { Flexor, Spinner } from "shared/src/components";
import { classNames } from "shared/src/utils/classNames";
import { useAppSelector } from '../hooks'
import { Link, useParams } from "wouter";
import WorkScheduleCalendarView from "./WorkScheduleCalendarView";
import WorkScheduleTableView from "./WorkScheduleTableView";
import PageEmptyState from "./PageEmptyState";
import { WorkSchedule } from "../types/WorkSchedule";
import { Button } from "shared/src/components/ui/Button";

export function renderDataAttribute(data: any) {
  return (<span className={classNames(!data ? 'italic text-gray-600' : '')}>{data ? data : 'Not specified'}</span>);
}

export default function WorkSchedules() {
  const selectedElectionId = useAppSelector((state) => state.election.electionId);
  const [loadingWorkSchedules, setLoadingWorkSchedules] = useState<boolean>(false);
  const [workSchedules, setWorkSchedules] = useState<WorkSchedule[]>([]);
  const [workScheduleId, setWorkScheduleId] = useState<string>('');
  const [showWorkScheduleConfirmation, setShowWorkScheduleConfirmation] = useState<boolean>(false);
  const [isConfirmSelected, setIsConfirmSelected] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { viewName = 'table' } = useParams();

  useEffect(() => {
    if (!selectedElectionId) return;

    loadWorkSchedules();
  }, [selectedElectionId]);

  function loadWorkSchedules() {
    setLoadingWorkSchedules(true);

    getWorkSchedule(selectedElectionId).then((response: WorkSchedule[]) => setWorkSchedules(response))
    .catch(() => {
      setErrorMessage('Could not load work schedules');
    })
    .finally(() => setLoadingWorkSchedules(false));
  }

  function openScheduleConfirmationModal(workSchedule: WorkSchedule) {
    setWorkScheduleId(workSchedule.Id);
    setShowWorkScheduleConfirmation(true);
  }

  function closeScheduleConfirmationModal() {
    setShowWorkScheduleConfirmation(false);
    loadWorkSchedules();
  }

  function openConfirmationDialog(workSchedule: WorkSchedule, confirm: boolean) {
    setIsConfirmSelected(confirm);
    openScheduleConfirmationModal(workSchedule);
  }

  return (
    <div className="mt-2">
      <Modal
        title='Work Schedule Confirmation'
        open={showWorkScheduleConfirmation}
        onClose={() => setShowWorkScheduleConfirmation(false)}
      >
        <ModalWorkScheduleConfirmation
          isConfirmSelected={isConfirmSelected}
          closeModal={closeScheduleConfirmationModal}
          workScheduleId={workScheduleId}
        />
      </Modal>

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 mb-4">Work Schedule</h1>
          <div className='lg:flex justify-between items-center'>
            <div className='space-y-2 text-sm text-gray-700'>
              <p>Your work schedule is displayed here. You are currently viewing the schedule in grid format.</p>
              <div className='flex md:flex-row flex-row-reverse items-center lg:space-x-2'>
                <span className='hidden lg:inline'>Use the</span>
                <strong className='p-1 flex space-x-2 px-2 mx-2 items-center border border-gray-300 shadow-sm rounded-lg'>
                  <span className='h-4 w-4 rotate-45 rounded-full bg-gradient-to-r from-green-500 via-gray-500 to-70% to-red-500'></span>
                  <span>Response</span>
                  <ChevronDownIcon className='h-4 w-4' />
                </strong>
                <span><span className='inline lg:hidden'>Use the response</span> button to alert staff which scheduled times you are able to fulfill.</span>
              </div>
            </div>

            <Flexor justify='center' className=' mt-4 lg:mt-0'>
              <Link to='/work_schedule'>
                <Button className={classNames(viewName === 'table' ? '' : 'bg-gray-200 hover:bg-gray-300 text-gray-900', 'rounded-r-none w-full lg:w-auto')}>
                    <div className='flex justify-center items-center w-full space-x-2'>
                    <TableCellsIcon className='h-5 w-5' />
                    <div>Table</div>
                  </div>
                </Button>
              </Link>
              <Link to='/work_schedule/calendar'>
                <Button className={classNames(viewName === 'table' ? 'bg-gray-200 hover:bg-gray-300 text-gray-900' : '', 'rounded-l-none w-full lg:w-auto')}>
                  <div className='flex justify-center items-center w-full space-x-2'>
                    <CalendarDaysIcon className='h-5 w-5' />
                    <div>Calendar</div>
                  </div>
                </Button>
              </Link>
            </Flexor>
          </div>
        </div>
      </div>

      <div className="-mx-4 mt-8 sm:-mx-0">
        {
          loadingWorkSchedules && !workSchedules.length ? (
            <div className='w-full flex justify-center items-center h-24 my-24 space-x-2'>
              <Spinner show />
              <span className='text-lg'>Loading work schedules...</span>
            </div>
          ) : null
        }
        {
          !loadingWorkSchedules && !workSchedules.length ? (
            <PageEmptyState icon={CalendarIcon} errorMessage={errorMessage} emptyMessageHeader='No work schedules' emptyMessageSubHeader='Check back soon to keep up-to-date with your schedule.' reload={loadWorkSchedules} />
          ) : null
        }
        {
          !loadingWorkSchedules && workSchedules.length ? (
            <>
              { viewName === 'table' ? <WorkScheduleTableView openConfirmationDialog={openConfirmationDialog} workSchedules={workSchedules} /> : null }
              { viewName === 'calendar' ? <WorkScheduleCalendarView workSchedules={workSchedules} openConfirmationDialog={openConfirmationDialog} /> : null }
            </>
          ) : null
        }
      </div>
    </div>
  )
}
