import WorkScheduleResponseMenu from "./WorkScheduleResponseMenu";
import React, { ReactNode, useState } from "react";
import { renderDataAttribute } from "./WorkSchedules";
import DateFormatter from 'shared/src/components/DateFormatter';
import { OpenConfirmationModal, WorkSchedule } from "../types/WorkSchedule";
import { ConfirmationStatus } from "../enums/confirmationStatus";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";
import { Button } from "shared/src/components/ui/Button";
import ScheduledCoworkersList from "./ScheduledCoworkersList";
import Modal from "shared/src/components/Modal";

export function getStatusLabel(workSchedule: WorkSchedule): ReactNode {
  switch (workSchedule.ConfirmationStatus) {
  case ConfirmationStatus.Confirmed:
    return (
      <>
        <div className='mr-0.5 rounded-full bg-green-500 h-3 w-3'></div>
        <span>Confirmed</span>
      </>
    )
  case ConfirmationStatus.Declined:
    return (
      <>
        <div className='mr-0.5 rounded-full bg-red-500 h-3 w-3'></div>
        <span>Declined</span>
      </>
    )
  case ConfirmationStatus["No Response"]:
    return (
      <>
        <div className='mr-0.5 rounded-full bg-gray-500 h-3 w-3'></div>
        <span>No response</span>
      </>
    )
  default:
    return null;
  }
}

type WorkScheduleTableViewProps = {
  workSchedules: WorkSchedule[],
  openConfirmationDialog: OpenConfirmationModal,
}

export default function WorkScheduleTableView({workSchedules, openConfirmationDialog}: WorkScheduleTableViewProps) {
  const [showScheduledCoworkers, setShowScheduledCoworkers] = useState<string>();

  return (
    <table className="w-full divide-y divide-gray-300">
      <thead>
      <tr>
        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:hidden table-cell"
        >
          Summary
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Date
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Time In
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Time Out
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell md:table-cell"
        >
          Location
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Role
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Address
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          View
        </th>
        <th>
          <ScreenReaderOnly>Response</ScreenReaderOnly>
        </th>
      </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
      {
        workSchedules.map((workSchedule) => {
          return (
            <tr key={workSchedule.Id}>
              <td className="px-3 py-4 text-sm text-gray-800 lg:table-cell">

                  <span className='hidden lg:inline'>{workSchedule.DisplayWorkDate}</span>
                  <dl className="font-normal lg:hidden">
                    <dd className="mt-1 truncate text-gray-700">
                      <DateFormatter dateString={workSchedule.DisplayWorkDate} withTime={false}/> from {renderDataAttribute(workSchedule.StartTime)} until {renderDataAttribute(workSchedule.EndTime)}
                    </dd>
                    <dd className="mt-1 truncate text-gray-700" id='role'>
                      <ScreenReaderOnly>, with the</ScreenReaderOnly> {renderDataAttribute(workSchedule.RoleName)}
                      <ScreenReaderOnly>role</ScreenReaderOnly>
                    </dd>
                    <dd className="mt-1 truncate text-gray-700">
                      <ScreenReaderOnly>, at</ScreenReaderOnly>
                      {renderDataAttribute(workSchedule.VotingLocationName)}
                      <ScreenReaderOnly>, at</ScreenReaderOnly>
                    </dd>
                    <dd className="mt-1 truncate text-gray-700">{renderDataAttribute(workSchedule.FullAddress)}</dd>
                  </dl>

              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell">
              {renderDataAttribute(workSchedule.StartTime)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell">
                {renderDataAttribute(workSchedule.EndTime)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell md:table-cell">
                {renderDataAttribute(workSchedule.VotingLocationName)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell">
                {renderDataAttribute(workSchedule.RoleName)}
              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-800 lg:table-cell">
                {renderDataAttribute(workSchedule.FullAddress)}
              </td>
              <td>
                <Modal
                  title={`Coworkers for ${workSchedule.DisplayWorkDate}`}
                  open={showScheduledCoworkers === workSchedule.Id}
                  onClose={() => setShowScheduledCoworkers(undefined)}
                >
                  <div className='min-h-40 relative'>
                    <ScheduledCoworkersList workScheduleId={workSchedule.Id}/>
                  </div>
                </Modal>
                <Button variant='quaternary' className='text-sm' onClick={() => setShowScheduledCoworkers(workSchedule.Id)}>Coworkers</Button>
              </td>
              <td>
                <WorkScheduleResponseMenu
                  label={getStatusLabel(workSchedule)}
                  onSelect={(selection) => {
                    openConfirmationDialog(workSchedule, selection);
                  }}
                />
              </td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}
