import React, { useEffect, useState } from "react";
import {
  editWorkSchedule,
  getWorkScheduleForEditing
} from "../fetchers";
import {useSelector} from "react-redux";
import { Flexor, Spinner } from "shared/src/components";
import { ConfirmationStatus } from "../enums/confirmationStatus";
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import DateFormatter from "shared/src/components/DateFormatter";
import { classNames } from "shared/src/utils/classNames";
import {renderDataAttribute} from "./WorkSchedules";
import { apiDateConverter } from "../utils/apiDateConverter";
import {ScreenReaderHidden, ScreenReaderOnly} from "shared/src/components/Accessibility";
import { Button } from "shared/src/components/ui/Button";
import Textarea from "shared/src/components/ui/Textarea";
import ScheduledCoworkersList from "./ScheduledCoworkersList";

type EditableWorkSchedule = {
  Id: string,
  ElectionId: string,
  EVUserId: string,
  AssignTo: string,
  Location: string,
  Precinct: string,
  PrecinctLocationName: string,
  Role: string,
  RoleName: string,
  WorkDate: string,
  StartTime: string,
  StartTime12Hr: string,
  EndTime: string,
  EndTime12Hr: string,
  Notes: string,
  ConfirmationStatus: string,
  ConfirmationReason: string,
  DisplayWorkDate: string
};

export default function ModalWorkScheduleConfirmation({
  workScheduleId,
  isConfirmSelected,
  closeModal
}: { workScheduleId: string, isConfirmSelected: boolean, closeModal: () => void }) {
  const [currentTab, setCurrentTab] = useState<'summary' | 'coworkers'>('summary');
  const [editedWorkSchedule, setEditedWorkSchedule] = useState<EditableWorkSchedule>();
  const [reason, setReason] = useState<string>('');
  const [confirmationStatus, setConfirmationStatus] = useState<any>('');
  const [saving, setSaving] = useState<boolean>(false);
  const currentUser = useSelector((state: any) => state.currentPollworkerUser.EVUserId);

  useEffect(() => {
    if (currentTab === 'summary') {
      getWorkScheduleForEditing(workScheduleId).then((response) => {
        setReason(response.ConfirmationReason);
        isConfirmSelected ? setConfirmationStatus(ConfirmationStatus.Confirmed) : setConfirmationStatus(ConfirmationStatus.Declined);
        setEditedWorkSchedule(response);
      });
    }
  }, [currentUser, workScheduleId, currentTab]);

  function uploadEditedWorkSchedule() {
    if (!editedWorkSchedule) return;

    setSaving(true);

    editWorkSchedule({
      ...editedWorkSchedule,
      ConfirmationReason: reason,
      ConfirmationStatus: confirmationStatus,
      WorkDate: apiDateConverter(editedWorkSchedule.WorkDate),
    }).then(() => {
      closeModal();
    }).finally(() => {
      setSaving(false);
    });
  }

  if (!editedWorkSchedule) return null;

  return (
    <div>
      <p className="mb-2 text-sm text-gray-700">
        Confirm or decline your work schedule here.
      </p>
      <div>
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            <button
              onClick={() => setCurrentTab('summary')}
              data-testid='tab-summary'
              className={classNames(
                currentTab === 'summary'
                  ? 'border-ev-red text-ev-red'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
              )}
            >
              <CalendarIcon
                className={classNames(
                  currentTab === 'summary' ? 'text-ev-red' : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              />
              <span>Summary</span>
            </button>
            <button
              onClick={() => setCurrentTab('coworkers')}
              data-testid='tab-coworkers'
              className={classNames(
                currentTab === 'coworkers'
                  ? 'border-ev-red text-ev-red'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
              )}
            >
              <UsersIcon
                className={classNames(
                  currentTab === 'coworkers' ? 'text-ev-red' : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              />
              <span>Coworkers</span>
            </button>
          </nav>
        </div>
      </div>
      <div className='sm:min-h-80 sm:max-h-80 min-h-96 max-h-96'>
        {
          currentTab === 'summary' ? (
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto space-y-3">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 text-sm my-5 ">
                  <div
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                  >
                    <div className="flex-shrink-0">
                      <MapPinIcon className='h-6 w-6' />
                      <ScreenReaderOnly>Location</ScreenReaderOnly>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <span className="absolute inset-0" />
                        <span>{renderDataAttribute(editedWorkSchedule.PrecinctLocationName)}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                  >
                    <div className="flex-shrink-0">
                      <ClockIcon className='h-6 w-6' />
                      <ScreenReaderOnly>Date and Time</ScreenReaderOnly>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <span className="absolute inset-0" />
                        <div>
                          <div><DateFormatter dateString={editedWorkSchedule.DisplayWorkDate} withTime={false} /></div>
                          <ScreenReaderHidden>{editedWorkSchedule.StartTime12Hr} &rarr; {editedWorkSchedule.EndTime12Hr}</ScreenReaderHidden>
                          <ScreenReaderOnly>{editedWorkSchedule.StartTime12Hr} until {editedWorkSchedule.EndTime12Hr}</ScreenReaderOnly>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Flexor className='mt-2'>
                  <span className="text-sm text-gray-900 font-medium">Are you able to work this schedule?</span>
                  <div className='space-x-3 flex'>
                    <div className="flex items-center">
                      <input
                        id='confirm'
                        name="confirmation-status"
                        type="radio"
                        value={ConfirmationStatus.Confirmed}
                        onChange={({target: {value}}) => setConfirmationStatus(value)}
                        defaultChecked={isConfirmSelected}
                        className="h-4 w-4 border-gray-300 text-ev-red focus:ring-ev-red"
                      />
                      <label htmlFor='confirm' className="ml-3 block text-sm font-medium text-gray-900">
                        Confirm
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id='decline'
                        name="confirmation-status"
                        type="radio"
                        value={ConfirmationStatus.Declined}
                        onChange={({target: {value}}) => setConfirmationStatus(value)}
                        defaultChecked={!isConfirmSelected}
                        className="h-4 w-4 border-gray-300 text-ev-red focus:ring-ev-red"
                      />
                      <label htmlFor='decline' className="ml-3 block text-sm font-medium text-gray-900">
                        Decline
                      </label>
                    </div>
                  </div>
                </Flexor>
                <div className='py-2'>
                  <Flexor className='text-gray-700 text-sm'>
                    <p className="font-medium">Reason</p>
                    <span aria-hidden='true'>{reason?.length || 0} / 1000</span>
                    <span id='textbox-info' hidden>
                      Enter your reason here, {reason?.length || 0} out of 1000 characters used
                    </span>
                  </Flexor>
                  <Textarea
                    aria-labelledby='textbox-info'
                    rows={3}
                    onChange={({ target: {value} }: React.ChangeEvent<HTMLTextAreaElement>) => {
                      if (value.length > 1000) return;
                      setReason(value);
                    }}
                    value={reason}
                  />
                </div>
                <Flexor>
                  <Button variant='secondary' onClick={closeModal}>Cancel</Button>
                  <Button disabled={saving} className='space-x-2' onClick={uploadEditedWorkSchedule}>
                    <Spinner light show={saving} />
                    <span>Save</span>
                  </Button>
                </Flexor>
              </div>
            </div>
          ) : (
            <div>
              <span className="text-sm text-gray-700">
                <ScheduledCoworkersList workScheduleId={workScheduleId} />
              </span>
            </div>
          )
        }
      </div>
    </div>
  )
}
