import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { CheckBadgeIcon, ClockIcon, EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/outline";
import { ScreenReaderOnly, Spinner } from "shared/src/components";
import { renderDataAttribute } from "./WorkSchedules";
import { phoneNumberFormatter } from "shared/src/utils";
import React, { useEffect, useState } from "react";
import { getScheduledCoworkers } from "../fetchers";

type ScheduledCoworkersListProps = {
  workScheduleId: string,
}

export default function ScheduledCoworkersList({workScheduleId}: ScheduledCoworkersListProps) {
  const [loading, setLoading] = useState(true);
  const [scheduledCoworkers, setScheduledCoworkers] = useState<any[]>([]);

  useEffect(() => {
    if (!workScheduleId) return;

    setLoading(true);

    getScheduledCoworkers(workScheduleId).then((response) => {
      setScheduledCoworkers(response);
    }).finally(() => {
      setLoading(false);
    });
  }, [workScheduleId]);

  if (loading) {
    return (
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center space-x-2'>
        <Spinner show/>
        <div>Loading coworkers...</div>
      </div>
    );
  }

  if (!scheduledCoworkers.length) {
    return (
      <div className="text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center text-gray-700">
        <div>No scheduled coworkers were found.</div>
      </div>
    );
  }

  return (
    <div className='max-h-96 sm:max-h-80 overflow-y-auto pr-4'>
      <SectionSubSubHeading>Coworkers working the same scheduled day and location</SectionSubSubHeading>
      {
        scheduledCoworkers.map((coworker) => {
          return (
            <div className='my-4' key={coworker.Name}>
              <dl className='flex flex-wrap rounded-lg border border-gray-300 bg-white px-6 py-6 shadow-sm space-y-2 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400'>
                <dd className='flex items-center basis-full sm:basis-2/3 min-w-0'>
                  <span className='pr-2'>
                    <UserIcon className='h-5 w-5'/>
                    <ScreenReaderOnly>Name</ScreenReaderOnly>
                  </span>
                  <span className='truncate text-ellipsis'>
                    {renderDataAttribute(coworker.Name)}
                  </span>
                </dd>
                <dd className='flex items-center basis-full sm:basis-1/3 min-w-0'>
                  <span className='pr-2'>
                    <CheckBadgeIcon className='h-5 w-5'/>
                    <ScreenReaderOnly>Role</ScreenReaderOnly>
                  </span>
                  <span className='truncate text-ellipsis'>
                    {renderDataAttribute(coworker.WorkerRole)}
                  </span>
                </dd>
                <dd className='flex items-center basis-full sm:basis-2/3 min-w-0'>
                  <span className='pr-2'>
                    <EnvelopeIcon className='h-5 w-5'/>
                    <ScreenReaderOnly>Email</ScreenReaderOnly>
                  </span>
                  <span className='truncate text-ellipsis'>
                    {renderDataAttribute(coworker.Email)}
                  </span>
                </dd>
                <dd className='flex items-center basis-full sm:basis-1/3 min-w-0'>
                  <span className='pr-2'>
                    <PhoneIcon className='h-5 w-5'/>
                    <ScreenReaderOnly>Phone</ScreenReaderOnly>
                  </span>
                  <span className='truncate text-ellipsis'>
                    {renderDataAttribute(phoneNumberFormatter({value: coworker.CellPhone}))}
                  </span>
                </dd>
                <dd className='flex items-center basis-full min-w-0'>
                  <span className='pr-2'>
                    <ClockIcon className='h-5 w-5'/>
                    <ScreenReaderOnly>Time</ScreenReaderOnly>
                  </span>
                  <span className='truncate text-ellipsis'>
                    {renderDataAttribute(coworker.WorkerStartTime)} until {renderDataAttribute(coworker.WorkerEndTime)}
                  </span>
                </dd>
              </dl>
            </div>
          )
        }
      )}
    </div>
  )
}
