import { useAppSelector } from "../hooks";
import React, { useMemo } from "react";
import { EasyVoteIcon } from "shared/src/components";
import { getWebsiteUserFromLocalStorage } from "../utils/localStorage";
import { Link } from "wouter";
import useIsLoggedIn from "./selectors/useIsLoggedIn";

export default function DomainLogo({ authed = false }: { authed?: boolean }) {
  const currentUser = useAppSelector((state) => state.currentPollworkerUser);
  const isLoggedIn = useIsLoggedIn();

  const logo = useMemo(() => {
    const websiteUser = getWebsiteUserFromLocalStorage();

    return websiteUser ?
      authed ?
        <img className='max-w-24 sm:max-w-32 max-h-16'
             src={`${process.env.REACT_APP_DOMAIN}/images/${websiteUser.CustomerId}/logo`}
             alt={`Logo for ${currentUser?.Customer?.Name}`}
             aria-hidden='true'/>
        :
        <img className='max-w-24 sm:max-w-32 max-h-24'
             src={`${process.env.REACT_APP_DOMAIN}/images/${websiteUser.CustomerId}/logo`}
             alt={`Logo for ${currentUser?.Customer?.Name}`}
             aria-hidden='true'/>
      : (
        <EasyVoteIcon className='h-8 w-auto' />
      );
  }, [currentUser]);

  return (
    <Link to='/' className="-m-1.5 p-1.5">
      {logo}
    </Link>
  )
}
