import React, {ReactNode} from 'react';
import { classNames } from "../utils/classNames";

type FlexorProps = {
  children: ReactNode;
  justify?: string;
  items?: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export default function Flexor(props: FlexorProps): JSX.Element {
  return (
    // @ts-ignore
    <div {...props} disabled={props.disabled || false} className={classNames(
      'flex',
      classNames(props.justify === 'start' && 'justify-start'),
      classNames(props.justify === 'center' && 'justify-center'),
      classNames(props.justify === 'around' && 'justify-around'),
      classNames((props.justify === 'between' || !props.justify) && 'justify-between'),
      classNames(props.justify === 'end' && 'justify-end'),
      classNames(props.items === 'start' ? 'items-start' : ''),
      classNames(props.items === 'end' ? 'items-end' : ''),
      classNames((props.items === 'center' || !props.items) ? 'items-center' : ''),
      props.className,
    )}>
      {props.children}
    </div>
  )
}
