import { AnyAction } from "@reduxjs/toolkit";
import {CURRENT_WORK_SCHEDULE_ACTION_TYPES} from "../actions/currentWorkSchedule";

const initialState = {};

export function currentWorkScheduleReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case CURRENT_WORK_SCHEDULE_ACTION_TYPES.SET_CURRENT_WORK_SCHEDULE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
