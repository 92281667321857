import {combineReducers} from "redux";
import electionReducer from "./election";
import {currentPollworkerUserReducer} from "./currentUser";
import {currentCustomerReducer} from "./currentCustomer";
import {
  WorkScheduleProps, CookieAlert,
  PollworkerPublicCustomer,
  PollworkerPublicUser
} from "../../types";
import {currentWorkScheduleReducer} from "./currentWorkSchedule";

export type PollworkerPublicState = {
  electionId: string,
  election: {
    electionId: string,
  },
  workScheduleId: string,
  currentPollworkerUser: PollworkerPublicUser
  currentCustomer: PollworkerPublicCustomer
  currentWorkSchedule: WorkScheduleProps,
  cookieAlert: CookieAlert,
}

export default combineReducers({
  election: electionReducer,
  currentCustomer: currentCustomerReducer,
  currentWorkSchedule: currentWorkScheduleReducer,
  currentPollworkerUser: currentPollworkerUserReducer,
});
