import React, { useEffect, useMemo, useState } from "react";
import { getAvailabilitySurveyWorkDates, saveAvailabilitySurveyWorkDates } from "../fetchers";
import { useParams } from "wouter";
import Calendar from "./Calendar";
import dayjs, { Dayjs } from "dayjs";
import { Alert, AlertDescription } from "shared/src/components/ui";
import { Flexor } from "shared/src/components";
import {
  BookmarkSquareIcon,
  CalendarDaysIcon,
  ChevronDownIcon,
  NoSymbolIcon
} from "@heroicons/react/24/outline";
import { CheckCircleIcon, CursorArrowRaysIcon } from "@heroicons/react/24/solid";
import DateFormatter from "shared/src/components/DateFormatter";
import WorkScheduleResponseMenu from "./WorkScheduleResponseMenu";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { classNames } from "shared/src/utils/classNames";
import { toast } from "react-toastify";
import { apiDateConverter } from "../utils/apiDateConverter";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";

type WorkDate = {
  DateSurveyed: string,
  IsAvailable?: boolean,
}

type WorkDatePayload = {
  LogoPath: string,
  CustomerName: string,
  City: string,
  State: string,
  Zip: string,
  Phone: string,
  ImgPath: string,
  UserName: string,
  ElectionName: string,
  SurveyDates: Dayjs[],
  WorkDates: WorkDate[],
  WelcomeMessage: string,
  BackgroundColor: string,
  TextColor: string,
}

export default function AvailabilitySurvey() {
  const [scheduleData, setScheduleData] = useState<WorkDatePayload>();
  const [workDates, setWorkDates] = useState<WorkDate[]>([]);
  const [startDate, setStartDate] = useState(dayjs());
  const [show404, setShow404] = useState(false);
  const {scheduleSurveyId, evUserId} = useParams();

  useEffect(() => {
    loadWorkDates();
  }, [scheduleSurveyId, evUserId]);

  const isComplete = useMemo(() => !!workDates.filter(({ IsAvailable: isAvailable }) => Boolean(isAvailable)).length, [workDates])

  async function loadWorkDates() {
    if (!scheduleSurveyId || !evUserId) return;

    return getAvailabilitySurveyWorkDates(scheduleSurveyId, evUserId).then((availWorkDates) => {
      setScheduleData(availWorkDates);

      const workDates = availWorkDates.WorkDates
      .reduce((acc: { [key: string]: WorkDate }, date: WorkDate): {[key: string]: WorkDate} => {
        return {
          ...acc,
          [date.DateSurveyed]: date
        };
      }, {});

      const surveyDates = availWorkDates.SurveyDates
        .reduce((acc: { [key: string]: WorkDate }, date: string): {[key: string]: WorkDate} => {
          return {
            ...acc,
            [date]: { DateSurveyed: date }
          };
        }, {});

      const workDatesTransformed = (Object.values({...surveyDates, ...workDates}) as WorkDate[]).map((workDate: WorkDate): WorkDate => {
        return { ...workDate, DateSurveyed: apiDateConverter(workDate.DateSurveyed)! };
      });

      setWorkDates(workDatesTransformed);
      setStartDate(dayjs(workDatesTransformed[0].DateSurveyed));
    }).catch(() => {
      setShow404(true);
    });
  }

  function responseMenuLabel(isAvailable?: boolean) {
    let indicatorElem = null;

    switch (isAvailable) {
    case true:
      indicatorElem = (
        <>
          <div className='rounded-full h-3 w-3 bg-green-500'></div>
          <span>Confirmed</span>
        </>
      )
      break;
    case false:
      indicatorElem = (
        <>
          <div className='rounded-full h-3 w-3 bg-red-500'></div>
          <span>Decline</span>
        </>
      )
      break;
    case undefined:
      indicatorElem = (
        <>
          <div className='rounded-full h-3 w-3 bg-gray-500'></div>
          <span>No Response</span>
        </>
      )
      break;
    }

    return (
      <Flexor className='space-x-1'>
        {indicatorElem}
      </Flexor>
    )
  }

  function updateSchedule(dateSurveyed: string, selection: boolean) {
    if (!scheduleSurveyId || !evUserId) return;

    const savePromise = new Promise(async (resolve, reject) => {
      await saveAvailabilitySurveyWorkDates(scheduleSurveyId, evUserId, dateSurveyed, selection)
      .then((isSaved) => {
        if (!isSaved) return reject();

        setTimeout(() => {
          loadWorkDates().then(resolve);
        }, 1500);
      })
      .catch(reject);
    });

    toast.promise(savePromise, {pending: 'Updating availability...', success: 'Saved!', error: 'Could not be saved, try again.'})
  }

  if (show404) {
    return (
      <div className='rounded-lg w-full h-96 border-dashed border-2 flex flex-col items-center justify-center space-y-5'>
        <NoSymbolIcon className='h-14 w-14' />
        <h1 className='font-semibold' role="heading">The Availability Survey could not be found</h1>
        <div>Make sure you have the correct survey link. Otherwise please reach out to get a new link.</div>
      </div>
    )
  }

  return (
    <div role="form">
      {
        isComplete ? (
          <Alert className="mb-5" variant="success">
            <CheckCircleIcon className='size-5' />
            <AlertDescription>You have completed the availability survey. Feel free to make any changes you need to.</AlertDescription>
          </Alert>
        ): (
          <Alert className="mb-5" variant="information">
            <CursorArrowRaysIcon className='size-5' />
            <AlertDescription>{scheduleData?.WelcomeMessage}</AlertDescription>
          </Alert>
        )
      }
      <Flexor>
        <h1
          className="text-base font-semibold leading-6 text-gray-900 mb-4"
          role="heading"
        >{scheduleData?.UserName ? `${scheduleData?.UserName}'s ` : ''}Availability Survey</h1>
        <div className='flex items-center space-x-1'>
          <BookmarkSquareIcon className='h-5 w-5' />
          <span className='font-semibold'>{scheduleData?.ElectionName}</span>
        </div>
      </Flexor>
      <div className='lg:flex justify-between items-center'>
        <div className='space-y-2 text-sm text-gray-700'>
          <div className='flex lg:flex-row flex-row-revedrse items-center lg:space-x-2'>
            <span className='hidden lg:inline'>Use the</span>
            <strong className='p-1 flex space-x-2 px-2 mx-2 items-center border border-gray-300 shadow-sm rounded-lg'>
              <span className='h-4 w-4 rotate-45 rounded-full bg-gradient-to-r from-green-500 via-gray-500 to-70% to-red-500'></span>
              <span>Response</span>
              <ChevronDownIcon className='h-4 w-4' />
            </strong>
            <span><span className='inline lg:hidden'>Use the response</span> button to alert staff which scheduled times you are able to fulfill.</span>
          </div>
        </div>
      </div>
      <div className='lg:flex lg:space-y-0 space-y-10 gap-5 mt-5'>
        <div className='lg:w-1/2'>
          <SectionSubSubHeading>Dates</SectionSubSubHeading>
          <div className='flex flex-col mt-14 justify-center space-y-5'>
            {
              workDates.map(({ DateSurveyed: dateSurveyed, IsAvailable: isAvailable }) => (
                <div key={dateSurveyed} className={classNames(dateSurveyed === startDate.toISOString() ? 'ring-2 ring-ev-red' : '', 'border rounded-lg p-4 flex justify-between items-center font-semibold')} onClick={() => setStartDate(dayjs(dateSurveyed))}>
                  <Flexor className='space-x-2'>
                    <CalendarDaysIcon className='h-5 w-5' />
                    <div><DateFormatter dateString={dateSurveyed} withTime={false} /></div>
                  </Flexor>
                  <div>
                    <WorkScheduleResponseMenu label={responseMenuLabel(isAvailable)} onSelect={(selection) => updateSchedule(dateSurveyed, selection)} />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className='lg:w-1/2'>
          <ScreenReaderOnly>Visual representation of availability in a calendar view</ScreenReaderOnly>
          <Calendar startDay={startDate} onChange={setStartDate} aria-hidden="true"/>
        </div>
      </div>
    </div>
  )
}
